import Layout from "../../components/layout"
import "/src/styles/menu.css"
import { Link } from "gatsby"
import React from "react"

function CourseMenu() {
  return (
    <>
      <Layout route="/courses">
        <div className="menuPage">
          <div className="grid grid-rows-2">
            <div class="grid grid-cols-12">
              <h3 class="head col-span-10 col-start-2">
                A variety of courses to choose from..
              </h3>
            </div>
            <div class="grid grid-cols-12">
              <div className="col-span-7 col-start-3 underline">.</div>
            </div>
          </div>
          <div className="grid  md:grid-rows-1">
            <div class="grid grid-cols-11">
              <div class="menuCard one col-start-2 col-span-9  md:col-start-2 md:col-span-5 rounded overflow-hidden shadow-lg">
                <div class="image">.</div>
                <div class="cardText px-6 py-4">
                  <div class="font-bold text-xl mb-2">YOGA CIRCLE</div>
                  <Link to="/courses/yoga">
                    <button type="button" class="glow-button">
                      Register Now
                    </button>
                  </Link>
                </div>
              </div>

              <div class="menuCard two  col-start-2 col-span-9 md:col-start-8 md:col-span-3 rounded overflow-hidden shadow-lg">
                <div class="image">.</div>
                <div class="cardText px-6 py-4">
                  <div class="font-bold text-xl mb-2">ONE-TO-ONE COACHING</div>
                  <Link to="/courses/oneOne">
                    <button type="button" class="glow-button">
                      Register Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-rows-1 mt-6 pb-7">
            <div class="grid grid-cols-12">
              <div class="menuCard three  col-start-2 col-span-10 md:col-start-2 md:col-span-10 rounded overflow-hidden shadow-lg">
                <div class="image">.</div>
                <div class="cardText px-6 py-4">
                  <div class="font-bold text-xl mb-2">
                    AYURVEDA CONSULTATION
                  </div>
                  <Link to="/courses/consultation">
                    <button type="button" class="glow-button">
                      Register Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default CourseMenu
